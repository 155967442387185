<template>
  <div>
    <div class="container-fluid overflow-auto">
      <div class="row">
        <Sidebar />
        <div class="col-md-10 offset-md-2 pr-10  mt-5">
          <Rule
            @download-rule="downloadRule"
            @view-rule="viewRule"
            @edit-rule="editRule"
            @delete-rule="deleteRule"
            @approve-rule="approveRule"
            :rules="rules"
            :dashboardType="dashboardType"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rule from "../components/Rule.vue";
import Sidebar from "../components/Sidebar.vue";
import { RuleFunctions } from "../common/rule-functions.js";
import { CommonFunctions } from "../common/common-functions.js";

let datares;
export default {
  name: "RuleDashboard",
  components: {
    Rule,
    Sidebar,
  },
  props: {
    type: String,
  },
  data() {
    return {
      rules: [],
      ruleName: "",
      ruleDesc: "",
      decisionRules: {},
      dashboardType: "All Rules",
    };
  },
  methods: {
    editRule(rule) {
      let self = this;
      this.ruleName = rule.name ? rule.name : "";
      this.ruleDesc = rule.remarks ? rule.remarks : "";
      this.$router.push({
        name: "EditRule",
        params: {
          id: rule.id,
          name: rule.name,
          type: this.type,
        },
      });
    },

    downloadRule(id, version) {
      let model = 'DecisionTable';
      if(this.type === 'approve') model = 'DecisionTableMod';
      RuleFunctions.downloadRule(id, model, version);
    },

    fetchRules(ruleType = "all") {
      this.setDashBoardType(ruleType);
      let url = `/api/DecisionTables`;
      // if (ruleType === "me")
      //   url +=
      //     "?filter[where][_modifiedBy]=" + sessionStorage.getItem("username");
      if (ruleType === "approve") url = "/api/DecisionTableMods";
      let datares = fetch(url)
        .then((response) => response.json())
        .then((datares) => {
          this.rules = [];
          if (datares.length) {
            if (ruleType === "me") {
              datares = datares.filter(
                (r) =>
                  r._createdBy === sessionStorage.getItem("username") ||
                  r._modifiedBy === sessionStorage.getItem("username")
              );
            }
              if (ruleType === "approve") {
              datares = datares.filter(
                (r) =>
                  r._modifiedBy !== sessionStorage.getItem("username")
              );
            }
            let count = 1;
            datares.map((m) => {
              m.srlno = count;
              count++;
              m.createdOnFormatted = CommonFunctions.convertTime(m._createdOn,false);
              m.modifiedOnFormatted = CommonFunctions.convertTime(m._modifiedOn,false);
              //m.custVersion = "v" + m.custVersion;
              return m;
            });
            this.rules = datares;
          }
          // if (datares && datares.length) {
          //     let rules = document.getElementById("rulelist");
          //     Object.keys(datares).map((key) => rules.add(new Option(datares[key].name, JSON.stringify(datares[key].value))));
          // }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },
    deleteRule(id, version) {
      console.log(id + ":" + version);
    },
    viewRule(rule) {
      if (rule) {
        this.$router.push({
          name: "ViewRule",
          params: {
            id: rule.id,
            name: rule.name,
            type: this.type,
          },
        });
      }
    },
    approveRule() {},
    setDashBoardType(type) {
      switch (type) {
        case "me":
          this.dashboardType = "My Rules";
          break;
        case "approve":
          this.dashboardType = "Pending Approval";
          break;
        default:
          this.dashboardType = "All Rules";
          break;
      }
    },
  },
  async created() {
    let ruleType = "all";
    if (window.location.href) {
      ruleType =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
    }
    if(sessionStorage.length == 0){
        this.$router.push({
          name: "Login",
        });
    }
    await this.fetchRules(ruleType);
  },
  watch: {
    $route(to, from) {
      //console.log('RulesDashboard', to.params.type)

      this.setDashBoardType(to.params.type);
      this.fetchRules(to.params.type);
    },
  },
};
</script>
<style scoped>
</style>